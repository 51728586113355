<template>
  <div class="delivery-box">
    <div class="header d-flex align-center justify-center">
      <div class="ml-auto">{{ value.zone_name }}</div>

      <div class="ml-auto">
        <slot name="delivery-zone-delete"></slot>
      </div>
    </div>

    <div class="body d-flex flex-sm-row flex-lg-column flex-column">
      <span class="dist-slider">
        <p class="mb-0 pb-5">Distance (miles)</p>

        <div class="my-slider pa-0 ma-0">
          <v-slider
            :max="4"
            thumb-color="#FFFFFF"
            background-color="#FFFFFF"
            :readonly="$store.state.setting.distributor.role === 'AU'"
            track-color="#CACEDA"
            track-fill-color="#CACEDA"
            thumb-size="6"
            step="1"
            height="5px"
            ticks="always"
            tick-size="5"
            hide-details
            v-model="value.distance_select"
          ></v-slider>
        </div>

        <div class="slider-label d-flex justify-space-between pt-3 mx-1">
          <div
            class="d-flex justify-start"
            :class="check_select(0)"
            style="width: 20%"
          >
            0-5
          </div>
          <div
            class="d-flex justify-start pl-2"
            :class="check_select(1)"
            style="width: 20%"
          >
            5-15
          </div>
          <div
            class="d-flex justify-center"
            :class="check_select(2)"
            style="width: 20%"
          >
            15-25
          </div>
          <div
            class="d-flex justify-end"
            :class="check_select(3)"
            style="width: 20%"
          >
            25-45
          </div>
          <div
            class="d-flex justify-end"
            :class="check_select(4)"
            style="width: 20%"
          >
            45-75
          </div>
        </div>
      </span>
      <span>
        <p class="ma-0 pt-5 pb-2 pt-lg-5 pt-sm-0 pb-lg-1 pb-sm-4">Shipping price</p>

        <div>
          <app-my-currency-simple
            v-model="value.shipping_price"
          ></app-my-currency-simple>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {};
  },

  methods: {
    check_select(v) {
      return this.distance_select == v ? "slider-label--active" : "";
    },
  },
};
</script>

<style lang="scss">
.delivery-zone {
  .delivery-box {
    height: 268px;
    width: 388px;
    background: #ffffff;
    border: 1px solid #caceda;
    border-radius: 4px;

    .header {
      height: 54px;
      padding: 16px 24px 16px 24px;
      border-bottom: 1px solid #caceda;
      font-family: Inter-SemiBold;
      font-size: 16px;
      color: #212121;
      letter-spacing: 0;
      text-align: center;

      img {
        cursor: pointer !important;
      }
    }

    .body {
      padding: 24px 24px 24px 24px;
      height: 80%;

      p {
        font-family: Inter-Regular;
        font-size: 13px;
        color: #7587a3;
      }

      .v-text-field__prefix {
        padding-right: 0px;
      }
    }

    .slider-label {
      font-family: Inter-Regular !important;
      font-size: 12px !important;
      color: #7587a3 !important;
    }

    .slider-label--active {
      font-family: Inter-SemiBold;
      font-size: 12px;
      color: #2974ff;
    }
  }
}

.delivery-zone .delivery-box:hover {
  border: 2px solid #2974ff;

  .header {
    border-bottom: 2px solid #2974ff;
    padding: 15px 23px 15px 23px;
  }

  .body {
    padding: 23px 23px 23px 23px;
  }
}

.my-slider {
  .v-input__control > .v-input__slot:focus-within {
    background: #ffffff !important;
  }
}
.v-slider:hover {
  cursor: all-scroll !important;
}

.v-slider__thumb:before {
  content: "";
  color: inherit;
  width: 0px !important;
  height: 0px !important;
  background: currentColor;
  opacity: 0.3;
  left: -12px;
  top: -12px;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
  pointer-events: none;
}

.v-slider__thumb-container > .v-slider__thumb {
  width: 16px !important;
  height: 16px !important;
  background: #ffffff !important;
  border: 5px solid #2974ff !important;
}

.v-slider__tick {
  background-color: #caceda !important;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  border-radius: 6px !important;
  height: 6px !important;
  width: 6px !important;
}
@media only screen and (max-width: 1024px) {
  .add-remove-item,
  .delivery-box {
    width: 100% !important;
  }
  .delivery-zone {
    .delivery-box {
      height: 180px;

      .dist-slider {
        width: 60%;
        margin-right: 20px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .delivery-zone {
    .delivery-box {
      height: 265px;

      .dist-slider {
        width: 100%;
        margin-right: 10px;
      }
    }
  }
}
</style>